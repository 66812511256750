.profile-student {
  .header {
    font-size: 20px;
    margin-bottom: 1.5rem;
    margin-top: -0.5rem;
  }
  .photo-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .upload-btn{
      margin: 0;
      margin-top: 1rem;
      border-radius: 20px;
    }
    .avatar-wrapper{
      width: 190px;
      height: 190px;
      padding: 1rem;
      margin-top: 1rem;
      background: url('../../../assets/images/back.png');
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center;
      .student-avatar{
          width: 150px;
          height: 150px;
          line-height: 100px;
      }
  }
  }
  .profile-card{
    border-color:  #ADCB69;
    background-color: unset;
  }
  .change-pass-btn {
    background-color: crimson;
    color: #ffffff;
    &:hover {
      background-color: crimson;
      color: #ffffff;
      opacity: 0.7;
    }
  }
  
  form {
    // disable overrides
    .ant-input[disabled] {
      color:  #545454 !important;
      background-color: #ffffff !important;
      cursor: unset;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selector{
      background-color: #ffffff;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
      color: #545454;
    }
  }

  .cohort-card {
    border: 1px solid fade(#F59121,  40%);
    .no-flex-column{
      flex-direction: row !important;
    }
  }
}