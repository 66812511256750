.reader{
    padding: 2rem 3rem;
    background: #ffff;
    @media screen and (max-width: 760px) {
       padding: 1rem;
    }
    .heading{
        border-bottom: 1px solid fade(#4f4f4f, 20);
        margin-bottom: 1rem;
        .heading-text{
            font-size: 20px;
            font-weight: bold;
            color: #F8B54D;
        }
    }
    

    .content{
        margin-top: 1rem;
        p{
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .attachments {
        h4{
            color: #4f4f4f;
            font-size: 20px;
            font-family: 'Dosis', sans-serif;
            margin-bottom: 1rem;
            span {
                font-family: Roboto;
                font-size: 14px;
            }
        }
        border: 1px solid #cccc;
        padding: 1rem;
        margin-bottom: 1rem;
        a {
            color: #5BC0EB;
            font-family: 'Courier New', Courier, monospace;
            font-size: 18px;
            font-weight: 400;
            svg {
                margin-bottom: -3px;
            }
        }
    }
    .date-created {
        color: #4f4f4f;
        font-weight: 500;
        font-size: 18px;
        @media screen and (max-width: 760px) {
            font-size: 14px;
        }
    }
    .controls{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        padding-right: 2rem;
        @media screen and (max-width: 760px) {
            padding-right: 0;
            margin-top: 1rem;
            button{
                margin-right: 0;
            }
        }
    }
}

.video-wrapper{
    //padding-right:3rem;
    @media screen and (max-width: 760px) {
        padding-right: 0;
    }
}