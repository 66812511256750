.learn-header{
    display: flex;
    .subject-info{
        @media screen and (max-width: 760px) {
            margin-left: 0.5rem;
            margin-top: auto;
            margin-bottom: auto;
        }
        p{
            font-size: 18px;
            margin: 0;
            padding: 0;
            color: fade(#fff, 85%);
            font-weight: 500;
            span{
                font-weight: bold;
            }
        }
        h5{
            font-size: 16px;
            color: fade(#4f4f4f, 80%);
        }
    
        .gx-icon-btn .toggler {
            h5 {
                font-size: 25px;
            }
        }
    }

    .space-mobile{
        margin-bottom: 4rem;
    }
}