
.header{
    margin-top: 1rem;
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    .header-wrapper {
        display:  flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 760px) {
            flex-direction: column-reverse;
            &>h3 {
                margin-top: 0.5rem;
            }
        }
    }
    h3{
        font-size: 25px;
        color: #4f4f4f;
        span{
            color: #F8B54D;
        }
    }
    .avatar-wrapper{
        width: 100px;
        height: 100px;
        padding: 1rem;
        background: url('../../../../assets/images/back.png');
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;
        .student-avatar{
            width: 80px;
            height: 80px;
            line-height: 100px;
            margin-left: -5px;
            margin-top: -7px;
        }
    }
}

.cohort-wrapper{
    h4.title{
        font-size: 20px;
        color: #777777;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-weight: 600;
        margin-left: 4px;
    }
}

.recent-activity{
    margin: 1rem 0;
    h4{
        font-size: 20px;
        color: #777777;
        margin-top: 2rem;
        margin-bottom: 1rem;
        &.title{
            font-weight: 600;
            margin-left: 4px;
        }
    }
}
