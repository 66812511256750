.custom-view-btn{
    padding: 0.2rem 1rem;
    cursor: pointer;
    background: none;
    border: none;
    color: #F6C140;
    font-size: 16px;
    &:hover{
        background: #F6C140;
        color: #4f4f4f;
        border-radius: 5px;
    }
}

.cohort{
    .content{
        h4{
            color: #4f4f4f;
            font-size: 18px;
        }
        small{
            font-size: 12px;
            font-weight: 400;
        }
    }
}