.activity-item{
  width: 100%;
  height: 160px;
  border: 1px solid fade(#4f9e4f,  40%);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 0.7rem;
  &:hover{
      background: #f4f4f4;
      cursor: pointer;
      span{
          &.view {
              svg{
                  color: fade(#FF9B42, 80%);
              }
              h6 {
                  color: fade(#FF9B42, 80%);
              }
          }
      }
  }
  span{
      
      &.icon{
          position: absolute;
          right: 1rem;
          top: 1rem;
          &.submitted{
              svg{
                  color: green;
              }
          }
          svg{
              font-size: 20px;
              color: fade(#FF9B42, 80%);
          }
      }
      &.view {
          bottom: 1rem;
          top: unset;
          display:  flex;
          svg{
              font-size: 20px;
              color: fade(#4f4f4f, 80%);
          }
          color: fade(#4f4f4f, 80%);
          h6 {
              margin-right: 5px;
          }
      }
  }
  h5{
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: #F8B54D;
      margin-bottom: 1rem;
  }
  h6{
      color: #4F4F4F;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      &.type {
          text-transform: uppercase;
          margin-bottom: 1rem;
      }
      &.date {
          span{
              font-weight: 500;
          }
      }
      span.score {
          font-weight: 500;
          span {
              color: #4F9D69;
              font-size: 20px;
          }
      }
      span.reject{
          color: crimson;
          font-weight: 500;
      }
  }
}