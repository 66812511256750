
.form-card{
    &.gx-card .ant-card-head-title{
        font-weight: 400 !important;
        font-size: 16px !important;
        text-transform: none !important;
    }
    .form-row{
        &.no-flex-column{
            flex-direction: row !important;
        }
    }
    .no-flex-column{
        flex-direction: row !important;
    }
}