
.deco-center{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #F4F4F4;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    flex-direction: column;
    img.logo{
        width: 120px;
        height: auto;
        margin-top: 40px;
        position: unset;
    }
    .form-wrapper{
        width: 30%;
        height: 60vh;
        margin-top: -30px;
        padding: 20px;
        .gx-login-text {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @media screen and (max-width: 760px) {
            width: 90%;
        }
         /* Small Devices, Tablets */
        @media only screen and (max-width : 1110px) and (min-width: 761px) {
            width: 60%;
        } 
        h3{
            font-family: 'Bubblegum Sans', cursive;
            color: #4f4f4f;
        }
        .input-field{
            width: 100%;
            label{
                font-size: 14px;
            }
        }
        .no-m-bottom{
            margin-top: -20px;
        }
    }
    img{
        position: absolute;
        &.footer{
            bottom: -20px;
            @media screen and (max-width: 760px) {
                bottom: 0;
            }
        }
        &.grass-side{
            left: -20px;
            @media screen and (max-width: 760px) {
                left: -65px;
            }
        }
        &.balls{
            right: 0;
            top: 300px;
            height: 120px;
            @media screen and (max-width: 760px) {
                right: -5px;
            }
        }
        &.gradient{
            top: 0;
            right: 0;
            height: 300px;
            @media screen and (max-width: 760px) {
               height: 150px;
            }
        }
    }
}