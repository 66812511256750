

.messaging-home {
  .icon-wrapper{
      position: absolute;
      right: 25px;
      top: 10px;
      svg {
          font-size: 24px;
      }
  }
  .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; 
      margin-bottom: 2rem;
      background-color: #fff;
      padding: 1rem;
      border-radius: 5px;
      border: 1px solid #ccc;
      h4{
          font-size: 20px;
          margin-left: 5px;
          color: #4f4f4f;
      }
      button {
        margin: 0;
      }
  }
  .activity-list {
      .ant-card-body {
          padding: 1.3rem;
          padding-bottom: 0;
      }
  }
  .card-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
      .description{
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          h4, h5 {
              font-family: 'Montserrat', sans-serif;
              &.title {
                  font-size: 18px;
                  color: #4F4F4F;
                  font-weight: 400;
                  font-family: 'Dosis';
                  font-size: 20px;
                  margin-bottom: 10px;
              }
              &.type {
                  margin-bottom: 1rem;
                  font-size: 14px;
                  color: #707070;
              }
          }         
      }
      .controls {
          border-top: 1px solid #f4f4f4;
          margin-top: 10px;
          padding: 5px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          button{
              margin: 0;
              &:hover {
                  background: #f4f4f4;
              }
              &.edit {
                  color: #f5c233;
                  font-weight: 400;
                  opacity: 0.9;
              }
              &.delete-btn{
                 color: #4f4f4f; 
                 opacity: 0.7;
              }
          }
      }
  }
}