
.ic-nav-item{
    position: relative;
    margin: 0.7rem 0 !important;
    i{
        margin-right: 10px !important;
        font-size: 25px !important;
        line-height: 17px;
        margin-left: 10px;
    }
    a{
        font-size: 20px;
        font-weight: 400;
        color: fade(#fff, 80%) !important;
        font-family: 'Bubblegum Sans', cursive;
    }
    .ic-bar{
        width: 5px;
        border-radius: 10px;
        background: #F6C140;
        position: absolute;
        right: 3px;
        height: 80%;
        margin-top: 5px;
        display: none;
    }
    &.ant-menu-item-selected {
        background: fade(#000, 20%) !important;
        a{
            color: #fff !important;
        }
        .ic-bar{
            display: block;
        }
        &:hover{
            background: fade(#000, 20%) !important;
        }
    }
    &.mini{
        i{
          margin-left: unset;
          margin-right: 20px;
        }
    }
    &:hover{
        a{
            color: #fff !important;
        }
    }
}

.ic-nav-item-sub{
    margin: 0.7rem 0 !important;
    &.mini{
        i{
          margin-left: unset;
          margin-right: 20px;
        }
    }
    &.ant-menu-submenu-active{
       i{
        color: #fff !important;
       }
       span{
        color: #fff !important;
       }
       .ant-menu-submenu-arrow{
        color: #fff !important;
       }
    }
    &.selected{
      background: fade(#000, 20%) !important;
    }
    .ant-menu-submenu-arrow{
        left: auto !important;
        right: 10px !important;
    }
    i{
        margin-right: 10px !important;
        font-size: 20px !important;
        margin-left: 10px;
        &:hover{
            color: #fff !important;
        }
    }
    span.item-title{
        font-size: 20px;
        font-weight: 400;
        color: fade(#fff, 80%) !important;
        font-family: 'Bubblegum Sans', cursive;
    }
    .ic-item-sub{
        position: relative;
        width: 100%;
        &.ant-menu-item-selected{
            .ic-bar{
                display: block;
            }
        }
        .ic-bar{
            width: 5px;
            border-radius: 10px;
            background: #F6C140;
            position: absolute;
            right: -150px;
            top: -10px;
            height: 25px;
            margin-top: 5px;
            display: none;
        }
        i{
            font-size: 16px !important;
            margin-left: 30px;
        }
        span{
            color: fade(#fff, 80%) !important;
            font-family: 'Bubblegum Sans', cursive;
        }
    }
}