.profile-teacher-full {
    .header {
      font-size: 20px;
      margin-bottom: 1.5rem;
      margin-top: -0.5rem;
    }
    .photo-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .upload-btn{
        margin: 0;
        border-radius: 20px;
      }
      .avatar-wrapper{
        width: 190px;
        height: 160px;
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;
        .teacher-avatar{
            width: 150px;
            height: 150px;
            line-height: 100px;
            border: 1px solid fade(#F59121,  40%);
        }
    }
    }
    .profile-card{
      border: 1px solid fade(#F59121,  40%);
      background-color: #fff;
      &.picture{
        border: 1px solid fade(#ccc,  95%);
      }
    }
    .change-pass-btn {
      background-color: crimson;
      color: #ffffff;
      &:hover {
        background-color: crimson;
        color: #ffffff;
        opacity: 0.7;
      }
    }
    
    form {
      // disable overrides
      .ant-input[disabled] {
        color:  #545454 !important;
        // background-color: #ffffff !important;
        //cursor: unset;
      }
      .ant-select-disabled.ant-select-multiple .ant-select-selector{
        //background-color: #ffffff;
      }
      .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
        color: #545454;
      }
    }
  
    .cohort-card {
      border-color:  #ADCB69;
      .no-flex-column{
        flex-direction: row !important;
      }
    }
  }