.student-activity-details{
  margin-bottom: 2rem;
  .top{
    font-size: 20px;
    font-weight: 500;
    color: #4f4f4f;
    margin-left: 0;
    margin-bottom: 2rem;
    text-transform: capitalize;
    span {
      color: #FCAF58;
    }
  }

  .activity-column{
    margin-bottom: 1rem !important;
    input{
      &.turnoff {
        color: #545454;
        background-color: #fff;
        border-radius: 5px;
        cursor: unset;
      }
    }
    .instruction{
      width: 100%;
      min-height: 100px;
      color: #545454;
      background-color: #fff;
      border-radius: 5px;
      cursor: unset;
      font-size: 16px;
      padding: 1rem;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
    }
    h4.key{
      font-size: 16px;
      color: fade(#4f4f4f, 70%);
      margin-bottom: 0.5rem;
    }
  }

  .activityFiles{
    h4{
      font-size: 16px;
      color: fade(#4f4f4f, 70%);
      margin-bottom: 0.5rem;
    }
    a {
      font-size: 18px;
      color: fade(#06f, 90%)
    }
    svg{
      font-size: 16px;
      color: #4f4f4f;
    }
    margin-bottom: 1rem !important;
  }

  .control {
    display: flex;
    flex-direction: row-reverse;
    button{
      &:hover{
        background: #77CABD;
        color: #fff;
        border-color: #77CABD;
      }
      &.cancel-btn {
        margin: 0;
        background: #fff;
        color: #77CABD;
        font-family: 'Bubblegum Sans', cursive;
        font-size: 18px;
        padding: 0 25px;
        border-color: #77CABD;
      }
    }
  }

  .submission {
    h4 {
      font-size: 20px;
      font-weight: 500;
      color: #4f4f4f;
      margin-bottom: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    textarea {
      font-size: 16px;
      line-height: 25px;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .embed-raper{
    h4 {
      font-weight: bold;
    }
  }

  .submitted{
    margin-top: 1rem;
    h4{
      color: #4f4f4f;
    }
  }
}