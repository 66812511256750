.ic-ft-bubble{
    font-family: 'Bubblegum Sans', cursive;
}

.ic-ft-dosis{
    font-family: 'Dosis', sans-serif;
}

button.btn-student{
    margin: 0;
    background: #77CABD;
    color: #fff;
    font-family: 'Bubblegum Sans', cursive;
    font-size: 18px;
    padding: 0 25px;
}

button.ytp-button{
    color: firebrick;
    display: none;
}

.no-padding-button-round {
    .ant-btn-round {
        padding: 0 15px;
    }
}