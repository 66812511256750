

.activities-home {
    .icon-wrapper{
        position: absolute;
        right: 25px;
        top: 10px;
        svg {
            font-size: 24px;
        }
    }
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        h4{
            font-size: 20px;
            margin-left: 10px;
            color: #4f4f4f;
        }
    }
    .activity-list {
        .ant-card-body {
            padding: 1.3rem;
            padding-bottom: 0;
        }
    }
    .card-content{
        display: flex;
        flex-direction: column;
        .description{
            h4, h5 {
                font-family: 'Montserrat', sans-serif;
                &.title {
                    font-size: 18px;
                    color: #4F4F4F;
                    font-weight: 500;
                }
                &.type {
                    margin-bottom: 1rem;
                    font-size: 14px;
                    color: #707070;
                }
            }
            .extras{
                display: flex;
                .bar{
                    width: 2px;
                    height: 20px;
                    background: #ccc;
                    margin-right: 10px;
                }
                h5{
                    margin-right: 10px;
                    &.cohort{
                        font-size: 18px;
                        font-family: 'Dosis', sans-serif;
                        font-weight: 500;
                        color: #4f4f4f;
                        margin-top: 3px;
                    }
                    span {
                        color: #E58F65;
                        font-weight: bold;
                        font-size: 20px;
                        font-family: 'Dosis', sans-serif;
                    }
                }
            }
           
        }
        .controls {
            border-top: 1px solid #f4f4f4;
            margin-top: 10px;
            padding: 5px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button{
                margin: 0;
                &:hover {
                    background: #f4f4f4;
                }
                &.edit {
                    color: #4f4f4f;
                    opacity: 0.9;
                }
                &.delete-btn{
                   color: #4f4f4f; 
                   opacity: 0.7;
                }
            }
        }
    }
}