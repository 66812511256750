
.dashboard {
  .heading {
    h3{
      font-size: 20px;
      color: fade(#4f4f4f, 70%);
      margin-bottom: 0.5rem;
      span{
        color: fade(#4f4f4f, 90%);
        font-family: 'Bubblegum Sans', cursive;
        font-size: 25px;
        color: #F5C233;
      }
    }
  }
}