// overide
@media screen and (max-width: 1366px){
    .ant-layout-sider{
        &.learn-sb{
            width: 300px !important
        }
    }
}

.ic-nav-item-student{
    position: relative;
    margin: 0.7rem 0 !important;
    height: 55px !important;
    a{
        &:hover{
            h4{
                color: fade(#fff, 80%);
            }
        }
    }
    .link-wrapper{
        display: flex;
        width: 100%;
        align-items: center;
        height: 100%;
        svg{
            font-size: 30px;
            margin-left: 10px;
            margin-right: 10px !important;
            color: fade(#fff, 60%);
        }   
        h4{
            margin: 0;
            font-size: 20px;
            font-weight: bold;
            color: fade(#fff, 60%);
            font-family: 'Montserrat', sans-serif;
        }
    }
    &.mini{
        .link-wrapper{
            h4{
                display: none;
            }
            svg{
                margin-left: unset;
                margin-right: 20px;
            }
        }
    }
    .ic-bar{
        width: 5px;
        border-radius: 10px;
        background: #F6C140;
        position: absolute;
        right: 3px;
        height: 80%;
        margin-top: 1px;
        display: none;
    }
    &.ant-menu-item-selected {
        background: fade(#000, 20%) !important;
        svg{
            color: #ADCB69;
        }
        h4{
            color: #fff;
        }
        .ic-bar{
            display: block;
        }
        &:hover{
            background: fade(#000, 20%) !important;
        }
    }
}

.sb-footer{
   padding-left: 1rem;
}
.sb-learn-topics{
    height: 100%;
    width: 100%;
    background: #84BED6;
    .sb-header{
        background: #84BED6;
        height: 70px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        button{
            margin: 0;
            font-size: 20px;
            color: fade(#fff, 80%);
        }
    }
    .scroll-height{
        height: calc(100vh - 117px) !important;
        background: fade(#000, 20%);
    }
    .topic{
        width: 100%;
        border-bottom: 1px solid fade(#4F4F4F, 40%);
        padding: 1rem 0px 1rem 10px;
        display: flex;
        align-items: center;
        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }
        span{
            font-size: 18px;
            color: fade(#fff, 60%);
        }
        h3{
            margin: 0;
            font-size: 16px;
            color: #FFFFFF;
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            margin-left: 5px;
        }
        &.active{
            border-right: 5px solid #F8B54D;
            span{
                color: fade(#F8B54D, 90%);
            }
        }
    }
}