
.new-topic{
    .heading{
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        align-items: center;
        h3{
            font-size: 18px;
            color: #4f4f4f;
            margin: 0;
        }
        button {
            margin: 0;
        }
    }
    &.gx-card .ant-card-head-title{
        font-weight: 400 !important;
        font-size: 16px !important;
        text-transform: none !important;
    }
    .form-row{
        &.no-flex-column{
            flex-direction: row !important;
        }
    }
    .no-flex-column{
        flex-direction: row !important;
    }
}