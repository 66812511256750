.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 10px 16px;
    border-top: 1px solid #e9e9e9;
    background: #fff;
    text-align: right;
  }

.new-activity-drawer {
    .ant-drawer-content-wrapper{
        width: 720px !important;
        .ant-drawer-body{
            padding: 24px;
        }

        .ant-drawer-close{
            top: 0;
        }
    }
}