
.message-detail {
  .message-detail {
    .ant-input[disabled]{
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .message-body{
    padding: 1rem;
    border: 2px solid #eee;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
}