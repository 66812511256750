.cohort-subject-item{
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border: 1px solid fade(#F59121,  40%);
    border-radius: 5px;
    padding: 0 1rem;
    @media screen and (max-width: 760px) {
        height: 130px;
        flex-direction: column;
        padding: 1rem;
        align-items: flex-start;
    }
    h4{
        margin: 0 !important;
        font-size: 20px;
        color: #4F4F4F !important;
        @media screen and (max-width: 760px) {
            & span::before {
                content: "\A";
                white-space: pre;
            }
        }
        span {
            font-size: 16px;
            font-weight: 400;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin-left: 1rem;
            opacity: 0.7;
        }
    }
}