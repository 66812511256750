// .panel{
    
// }

// [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
// .site-collapse-custom-collapse .site-collapse-custom-panel {
//     background: #f7f7f7;
//     border-radius: 2px;
//     margin-bottom: 24px;
//     border: 0px;
//     overflow: hidden;
// }

// .site-collapse-custom-collapse .site-collapse-custom-panel{
//     background: #fff;
//     border: 0 !important;
// }

.subjects{
    padding: 0 10px;
    width: 100%;
    .subject{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f4f4f4;
        padding: 10px 0;
        &:last-child{
            border: none;
        }
        h5{
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            span{
                margin-right: 10px;
                color: #F6C140;
            }
        }
        button {
            margin: 0;
            font-size: 15px;
            height: 30px;
            line-height: 30px;
            &:hover{
                color: #fff;
                background: #F6C140;
            }
        }
    }
}