
.support {
  .support-card{
    border-color:  #ADCB69;
    background-color: unset;
    @media screen and (max-width: 760px) {
      border-color: transparent;
      &>.ant-card-body {
        padding: 0;
      }
    }
    .support{
      .ant-card-head {
        background-color: fade(#ADCB69, 95%);
        .ant-card-head-wrapper{
          @media screen and (max-width: 760px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h5{
              margin: 0;
            }
            button {
              margin: 0;
            }
            .ant-card-extra{
              margin-left: unset;
              padding: 10px 0;
            }
          }
        }
        &>.ant-card-head-title {
          padding: unset;
          white-space: pre-wrap;
        }
        h5{
          margin: 0;
          color: #fff;
          font-size: 16px;
          text-transform: none;
        }
      }
      .description {
        font-size: 18px;
      }
    }
  }
}