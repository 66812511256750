
.gx-tabs-half .ant-tabs-nav-list, .gx-tabs-half .ant-tabs-nav:before{
  width:  100%;
}

.gx-tabs-half .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  width: 50%;
  text-align: center;
  display: block;
}

.gx-chat-user-item.active {
  background-color: rgba(248, 181, 77, 0.3) !important;
}

.gx-chat-main-header-info {
  .close-container{
    position: absolute;
    right: 10px;
  }
}
