.cohort-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4{
        color: #F6C140;
        font-size: 18px;
    }
}

.subject-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 1rem;
    h5{
        font-size: 18px;
        color: #4F4F4F;
        opacity: 0.8;
    }
}

.subject-item{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F0f0f0;
    align-items: center;
    height: 50px;
    padding: 0.5rem 0;
    h5{
        font-size: 16px;
        color: #4f4f4f;
        margin: 0;
    }
    .ant-btn{
        margin-bottom: 0;
    }
}