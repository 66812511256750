
 .reply-card{
    border-color:  #ADCB69;
    background-color: unset;
    @media screen and (max-width: 760px){
      border: unset !important;
      &>.ant-card-body {
        padding: 0;
      }
    }
    .ant-card-head {
      background-color: fade(#ADCB69, 95%);
      .ant-card-head-wrapper{
        @media screen and (max-width: 760px) {
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-start;
          padding: 0.5rem 0;
          h5{
            margin: 0;
          }
          button {
            margin: 0;
          }
          .ant-card-extra{
            margin-left: unset;
            padding: 10px 0;
          }
        }
      }
      .ant-card-head-title {
        padding: unset;
      }
      h5{
        margin: 0;
        color: #fff;
        font-size: 16px;
        text-transform: none;
      }
    }

  }
h4.closed-text {
  color: #4f4f4f;
  opacity: 0.8;
}
